import * as React from 'react';
import styled from 'styled-components';
import errorIcon from '../assets/icons/error.png';

const CenterVertical = styled.div`
  margin-top: 50%;
`;

const ErrorIcon = styled.img`
  margin-top: 10%;
  margin-bottom: 3%;
  width: 100px;
  height: 100px;
`;

const Message = styled.span`
  color: #acaaa8;
  font-size: 0.9em;
  display: block;
  width: 80%;
  margin: auto;
  margin-bottom: 5%;
  line-height: 1.5em;
  margin-top: 0;
`;

interface Props {
  message: string;
}

const ErrorPage = (props: Props): JSX.Element => {
  const { message } = props;

  return (
    <div>
      <CenterVertical>
        <ErrorIcon src={errorIcon} alt="Erorr Icon" />
        {typeof message === 'string' ? (
          <Message>{message}</Message>
        ) : (
          <Message>
            Something went wrong with your
            <br />
            purchase experience.
          </Message>
        )}
      </CenterVertical>
    </div>
  );
};

export default ErrorPage;
