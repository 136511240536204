import axios, { AxiosResponse } from 'axios';
import * as Sentry from '@sentry/browser';
import { getCurrentScope } from '../api';
import { PayPalElementProps } from '@adyen/adyen-web/dist/types/components/PayPal/types';
import type {
  markAsPaidType,
  markAsFailedType,
  WebDropInConfig
} from '../types';
import CheckoutClientError from '../errors';
import { getAdyenAmount } from '../monetary';
import UIElement from '@adyen/adyen-web/dist/types/components/UIElement';
import { PaymentAction } from '@adyen/adyen-web/dist/types/types';

const paypalWallet = 'PAYPAL';
const adyenProvider = 'ADYEN';
const paypalIntent = 'authorize';

interface HandleOnSubmitArgs {
  dropin: UIElement;
  cartId: string;
  baseUrl: string;
}
interface HandleOnAdditionalDetailsArgs {
  state: {
    data: {
      paymentData: Object;
      details: Object;
    };
  };
  dropin: UIElement;
  cartId: string;
  baseUrl: string;
  amount: number;
  currency: string;
  markAsPaid: markAsPaidType;
  markAsFailed: markAsFailedType;
}

interface GetPayPalArgs {
  webDropInConfig: WebDropInConfig;
  amount: number;
  currency: string;
  markAsPaid: markAsPaidType;
  markAsFailed: markAsFailedType;
}

interface PaymentSessionRequestData {
  payment_wallet: string;
}

interface PaymentSessionResponseData {
  payment_wallet_session: PaymentAction;
}

export const handleOnSubmit = async ({
  dropin,
  cartId,
  baseUrl
}: HandleOnSubmitArgs): Promise<void> => {
  return await new Promise((resolve, reject) => {
    axios
      .post<
        PaymentSessionRequestData,
        AxiosResponse<PaymentSessionResponseData>
      >(`${baseUrl}/_/v0/newstorecheckout/carts/${cartId}/payment_sessions`, {
        payment_wallet: paypalWallet
      })
      .then((response) => {
        const paymentSession = response?.data?.payment_wallet_session;
        dropin.handleAction(paymentSession);
        resolve();
      })
      .catch((error) => {
        Sentry.captureException(error);
        const newError = new CheckoutClientError(
          `There was an error. Try again.`
        );
        reject(newError);
      });
  });
};

export const handleOnAdditionalDetails = async ({
  state,
  dropin,
  cartId,
  baseUrl,
  amount,
  currency,
  markAsPaid,
  markAsFailed
}: HandleOnAdditionalDetailsArgs): Promise<void> => {
  return await new Promise((resolve, reject) => {
    const tokenData = {
      paymentData: state?.data?.paymentData,
      details: state?.data?.details
    };
    const token = JSON.stringify(tokenData);

    axios
      .post(`${baseUrl}/_/v0/newstorecheckout/carts/${cartId}/_pay`, {
        payment_token: token,
        payment_provider: adyenProvider,
        payment_wallet: paypalWallet,
        amount,
        currency
      })
      .then(() => {
        markAsPaid();
        dropin.setStatus('ready');
        resolve();
      })
      .catch((error) => {
        Sentry.captureException(error);
        const newError = new CheckoutClientError(
          `There was an error. Contact your associate.`
        );
        dropin.setStatus('error');
        markAsFailed(newError);
        reject(newError);
      });
  });
};

export const getPayPal = ({
  webDropInConfig,
  amount,
  currency,
  markAsPaid,
  markAsFailed
}: GetPayPalArgs): PayPalElementProps => {
  const { cartId, baseUrl } = getCurrentScope(window.location.href);
  const merchantId = webDropInConfig?.paypal?.merchantID ?? '';
  return {
    amount: {
      value: getAdyenAmount(currency, amount),
      currency
    },
    merchantId,
    environment: webDropInConfig.environment,
    countryCode: webDropInConfig.countryCode,
    intent: paypalIntent,

    onSubmit: (_state, dropin) => {
      void handleOnSubmit({ dropin, cartId, baseUrl });
    },
    onAdditionalDetails: (state, dropin) => {
      void handleOnAdditionalDetails({
        state,
        dropin,
        cartId,
        baseUrl,
        amount,
        currency,
        markAsPaid,
        markAsFailed
      });
    },
    onError: (state, dropin) => {
      let error = state;
      if (!(state instanceof CheckoutClientError)) {
        error = new Error(`Error from PayPal`);
      }
      Sentry.captureException(error);
      markAsFailed(error);

      if (dropin !== undefined) {
        dropin.setStatus('ready');
      }
    },
    onCancel: (state, dropin) => {
      const error = new Error(`User canceled the PayPal payment`);
      Sentry.captureException(error);
      markAsFailed(error);

      if (dropin !== undefined) {
        dropin.setStatus('ready');
      }
    }
  };
};
