import axios, { AxiosError, AxiosResponse } from 'axios';
import * as Sentry from '@sentry/browser';
import base64 from 'base-64';
import { getCurrentScope } from '../api';
import CheckoutClientError from '../errors';
import type {
  markAsPaidType,
  markAsFailedType,
  WebDropInConfig
} from '../types';
import { getAdyenAmount } from '../monetary';
import { ApplePayElementProps } from '@adyen/adyen-web/dist/types/components/ApplePay/types';

const APPLE_PAY_WALLET = 'APPLE_PAY';

interface PaymentSessionResponseData {
  payment_wallet_session: {
    merchantSessionIdentifier: string;
    epochTimestamp: number;
    merchantIdentifier: string;
    signature: string;
    displayName: string;
    domainName: string;
    nonce: string;
    expiresAt: number;
    request_id?: string;
  };
}

interface PaymentSessionRequestData {
  payment_wallet: string;
}

interface HandleOnAuthorizedArgs {
  resolve: () => void;
  reject: (error: Error) => void;
  event: ApplePayJS.ApplePayPaymentAuthorizedEvent;
  cartId: string;
  baseUrl: string;
  amount: number;
  currency: string;
  markAsPaid: markAsPaidType;
  markAsFailed: markAsFailedType;
}

interface HandleOnValidateMerchantArgs {
  resolve: (
    session: PaymentSessionResponseData['payment_wallet_session']
  ) => void;
  reject: (error: Error) => void;
  baseUrl: string;
  cartId: string;
  markAsFailed: markAsFailedType;
}

interface GetApplePayArgs {
  webDropInConfig: WebDropInConfig;
  amount: number;
  currency: string;
  markAsPaid: markAsPaidType;
  markAsFailed: markAsFailedType;
}

export const handleOnAuthorized = async ({
  resolve,
  reject,
  event,
  cartId,
  baseUrl,
  amount,
  currency,
  markAsPaid,
  markAsFailed
}: HandleOnAuthorizedArgs): Promise<void> => {
  try {
    const { emailAddress, familyName, givenName } =
      event?.payment?.shippingContact ?? {};

    await axios.post(`${baseUrl}/_/v0/newstorecheckout/carts/${cartId}/_pay`, {
      payment_token: base64.encode(
        JSON.stringify(event?.payment?.token?.paymentData)
      ),
      payment_provider: 'ADYEN',
      payment_wallet: APPLE_PAY_WALLET,
      amount,
      currency,
      customer: {
        email: emailAddress,
        name: `${givenName ?? ''} ${familyName ?? ''}`.trim()
      }
    });

    markAsPaid({
      firstName: givenName ?? '',
      lastName: familyName ?? ''
    });
    resolve();
  } catch (unkownError) {
    const error = unkownError as AxiosError<{ error_code: string }>;

    Sentry.captureException(error);

    const errorMessage =
      error?.response?.data?.error_code === 'adyen_refusal_error'
        ? 'Payment rejected. Try a different payment method.'
        : 'There was an error. Contact your associate.';

    const payError = new CheckoutClientError(errorMessage);

    markAsFailed(payError);
    reject(payError);
  }
};

export const handleOnValidateMerchant = async ({
  resolve,
  reject,
  baseUrl,
  cartId,
  markAsFailed
}: HandleOnValidateMerchantArgs): Promise<void> => {
  try {
    const response = await axios.post<
      PaymentSessionRequestData,
      AxiosResponse<PaymentSessionResponseData>
    >(`${baseUrl}/_/v0/newstorecheckout/carts/${cartId}/payment_sessions`, {
      payment_wallet: APPLE_PAY_WALLET
    });

    const paymentSession = response?.data?.payment_wallet_session;
    delete paymentSession.request_id;

    resolve(paymentSession);
  } catch (unkownError) {
    const error = unkownError as AxiosError<{ error_code: string }>;

    Sentry.captureException(error);

    const errorCode = error?.response?.data?.error_code;
    const errorMessage =
      errorCode === 'apple_certificate_error' ||
      errorCode === 'apple_session_error'
        ? 'Apple Pay not set up correctly. Contact your associate.'
        : 'There was an error. Try again.';

    const sessionError = new CheckoutClientError(errorMessage);

    markAsFailed(sessionError);
    reject(sessionError);
  }
};

// Version 3.23's `onAuthorized` handler is missing the `event` object
// containing the token. This is only a type issue
// that was fixed in version 5 of the library.
// Patching it here for the time being.
type PatchedApplePayElementProps = Omit<
  ApplePayElementProps,
  'onAuthorized'
> & {
  onAuthorized: (
    resolve: () => void,
    reject: () => void,
    event: ApplePayJS.ApplePayPaymentAuthorizedEvent
  ) => void;
};

export const getApplePay = ({
  webDropInConfig,
  amount,
  currency,
  markAsPaid,
  markAsFailed
}: GetApplePayArgs): PatchedApplePayElementProps => {
  const { cartId, baseUrl } = getCurrentScope(window.location.href);

  return {
    amount: {
      value: getAdyenAmount(currency, amount),
      currency
    },
    configuration:
      webDropInConfig?.applePay !== undefined
        ? { merchantId: webDropInConfig?.applePay?.merchantID }
        : {},
    shippingType: 'storePickup',
    supportedNetworks: webDropInConfig?.applePay?.supportedNetworks ?? [],
    merchantCapabilities: ['supports3DS'],
    requiredShippingContactFields: ['email', 'name'],
    totalPriceLabel: 'Total',
    countryCode: webDropInConfig.countryCode ?? '',

    onAuthorized: (resolve, reject, event) => {
      void handleOnAuthorized({
        resolve,
        reject,
        event,
        cartId,
        baseUrl,
        amount,
        currency,
        markAsPaid,
        markAsFailed
      });
    },

    onValidateMerchant: (resolve, reject) => {
      void handleOnValidateMerchant({
        resolve,
        reject,
        baseUrl,
        cartId,
        markAsFailed
      });
    }
  };
};
