import * as React from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/browser';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import {
  markAsFailedType,
  markAsPaidType,
  Metadata,
  setLoadingType,
  WebDropInConfig
} from '../types';
import { getApplePay } from '../dropin/applePay';
import { getGooglePay } from '../dropin/googlePay';
import { getPayPal } from '../dropin/paypal';
import { getCard } from '../dropin/card';

import { ApplePayElementProps } from '@adyen/adyen-web/dist/types/components/ApplePay/types';

const DropIn = styled.div`
  width: 80%;
  margin: auto;
`;

interface AdyenDropInState {
  open: boolean;
}

interface AdyenDropInProps {
  amount: number;
  currency: string;
  webDropInConfig: WebDropInConfig;
  markAsPaid: markAsPaidType;
  markAsFailed: markAsFailedType;
  setLoading: setLoadingType;
}

export default class AdyenDropIn extends React.Component<
  AdyenDropInProps,
  AdyenDropInState
> {
  dropin: any;

  metadata: Metadata;

  constructor(props: AdyenDropInProps) {
    super(props);
    this.metadata = {};
  }

  async componentDidMount() {
    const {
      webDropInConfig,
      amount,
      currency,
      markAsPaid,
      markAsFailed,
      setLoading
    } = this.props;

    try {
      // the dropin v3.x needs the array of payment methods
      // while the new version 5 will need the object containing the methods as an array:
      // As we only reverted back to v3 temporarily to have a transition period
      // for origin => clientKey (see https://docs.adyen.com/development-resources/client-side-authentication/migrate-from-origin-key-to-client-key)
      // We're leaviong this here for the near future:
      // const version5paymentMethods = webDropInConfig?.paymentMethods
      const version3paymentMethods =
        webDropInConfig?.paymentMethods?.paymentMethods;

      const checkout = await new AdyenCheckout({
        paymentMethods: version3paymentMethods,
        groups: webDropInConfig?.paymentMethods?.groups,
        originKey: webDropInConfig.originKey,
        locale: webDropInConfig.locale,
        environment: webDropInConfig.environment,
        countryCode: webDropInConfig.countryCode,
        paymentMethodsConfiguration: {
          // Casting here due to some wrong typings.
          // See `PatchedApplePayElementProps` in `src/dropin/applePay.ts`
          applepay: getApplePay({
            webDropInConfig,
            amount,
            currency,
            markAsPaid,
            markAsFailed
          }) as Partial<ApplePayElementProps>,
          paywithgoogle: getGooglePay({
            webDropInConfig,
            amount,
            currency,
            markAsPaid,
            markAsFailed
          }),
          paypal: getPayPal({
            webDropInConfig,
            amount,
            currency,
            markAsPaid,
            markAsFailed
          }),
          card: getCard({
            webDropInConfig,
            amount,
            currency,
            markAsPaid,
            markAsFailed,
            setLoading,
            setMetadata: this.setMetadata,
            getMetadata: this.getMetadata
          })
        },
        translations: {
          'en-US': {
            apartmentSuite: 'House number'
          }
        }
      });

      this.dropin = checkout.create('dropin').mount('#dropin');
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  setMetadata = (newMetadata: Metadata) => {
    this.metadata = {
      ...this.metadata,
      ...newMetadata
    };
  };

  getMetadata = () => {
    return this.metadata;
  };

  render() {
    return <DropIn id="dropin" />;
  }
}
