import Decimal from 'decimal.js';

const getCentsMultiplier = (currency: string): number => {
  if (currency === 'JPY') {
    return 1;
  }
  return 100;
};

export const getAdyenAmount = (currency: string, amount: number): number => {
  const multiplier = getCentsMultiplier(currency);

  return new Decimal(amount).times(multiplier).toNumber();
};

export const getCurrencyFractionDigits = (currency: string = 'USD'): number => {
  if (currency === 'JPY') {
    return 0;
  }
  return 2;
};
