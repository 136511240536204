import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './App';
import { getCurrentScope } from './api';
import GlobalStyle from './GlobalStyle';

const { stage } = getCurrentScope(window.location.href);

const sentryDSN =
  stage === 'p'
    ? 'https://9333b16ec0934e53b91b58e6c3d4a9c7@sentry.io/1444696'
    : 'https://53adfd70ceee426caef32b94bd52150b@sentry.io/1440246';

Sentry.init({
  dsn: sentryDSN
});

ReactDOM.render(
  <>
    <GlobalStyle />
    <App />
  </>,
  window.document.getElementById('root')
);
