import axios from 'axios';
import * as Sentry from '@sentry/browser';
import type {
  markAsPaidType,
  markAsFailedType,
  WebDropInConfig
} from '../types';
import CheckoutClientError from '../errors';

import { getCurrentScope } from '../api';
import { customerFirstAndLastName } from '../utils';
import { getAdyenAmount } from '../monetary';
import { GooglePayProps } from '@adyen/adyen-web/dist/types/components/GooglePay/types';

interface HandleOnAuthorizedArgs {
  data: google.payments.api.PaymentData;
  cartId: string;
  baseUrl: string;
  amount: number;
  currency: string;
  markAsPaid: markAsPaidType;
  markAsFailed: markAsFailedType;
}

interface GetGooglePayArgs {
  webDropInConfig: WebDropInConfig;
  amount: number;
  currency: string;
  markAsPaid: markAsPaidType;
  markAsFailed: markAsFailedType;
}

const googlePayWallet = 'GOOGLE_PAY';
const adyenProvider = 'ADYEN';
const environmentMapping = { test: 'TEST', live: 'PRODUCTION' };

const getCustomerInfo = (data: google.payments.api.PaymentData) => {
  const billingAddress = data.paymentMethodData?.info?.billingAddress;

  if (billingAddress === undefined)
    return {
      email: data.email
    };

  return {
    name: billingAddress.name,
    email: data.email,
    billing_address: {
      street: billingAddress.address1,
      house_number: 'N/A',
      postal_code: billingAddress.postalCode,
      city: billingAddress.locality,
      country: billingAddress.countryCode,
      ...(billingAddress.administrativeArea !== undefined
        ? { state: billingAddress.administrativeArea }
        : {})
    }
  };
};

export const handleOnAuthorized = async ({
  data,
  cartId,
  baseUrl,
  amount,
  currency,
  markAsPaid,
  markAsFailed
}: HandleOnAuthorizedArgs): Promise<void> => {
  return await new Promise((resolve, reject) => {
    const payment = data?.paymentMethodData?.tokenizationData;
    const googlePayToken = payment?.token;
    const customer = getCustomerInfo(data);
    const { firstName, lastName } = customerFirstAndLastName(customer.name);

    axios
      .post(`${baseUrl}/_/v0/newstorecheckout/carts/${cartId}/_pay`, {
        payment_token: googlePayToken,
        payment_provider: adyenProvider,
        payment_wallet: googlePayWallet,
        amount,
        currency,
        customer
      })
      .then(() => {
        markAsPaid({
          firstName,
          lastName
        });
        resolve();
      })
      .catch((error) => {
        Sentry.captureException(error);
        const newError = new CheckoutClientError(
          `There was an error. Contact your associate.`
        );
        markAsFailed(newError);
        reject(newError);
      });
  });
};

export const getGooglePay = ({
  webDropInConfig,
  amount,
  currency,
  markAsPaid,
  markAsFailed
}: GetGooglePayArgs): GooglePayProps => {
  const { cartId, baseUrl } = getCurrentScope(window.location.href);

  return {
    amount: {
      value: getAdyenAmount(currency, amount),
      currency
    },
    configuration:
      webDropInConfig.googlePay !== undefined
        ? {
            merchantId: webDropInConfig?.googlePay?.merchantID,
            gatewayMerchantId: webDropInConfig.merchantAccount ?? ''
          }
        : undefined,
    environment:
      typeof webDropInConfig.environment === 'string'
        ? environmentMapping[webDropInConfig.environment]
        : undefined,
    emailRequired: true,
    existingPaymentMethodRequired: false,
    billingAddressRequired: true,
    billingAddressParameters: {
      format: 'FULL'
    },

    onAuthorized: (data) => {
      void handleOnAuthorized({
        data,
        cartId,
        baseUrl,
        amount,
        currency,
        markAsPaid,
        markAsFailed
      });
    }
  };
};
