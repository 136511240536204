import * as React from 'react';
import styled from 'styled-components';

const Loader = styled.div`
  border: 1.5px solid #d591ab; /* Light grey */
  border-top: 1.5px solid rgba(0, 0, 0, 0); /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
  margin-top: 75%;

  @keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Loading = () => (
  <div>
    <Loader data-testid="loader" />
  </div>
);

export default Loading;
