import * as React from 'react';
import * as ReactGA from 'react-ga';
import styled from 'styled-components';
import type { CustomerName } from '../types';
import checkmarkIcon from '../assets/icons/success.png';

interface SuccessProps {
  customer?: CustomerName;
  tenantWebsite?: string;
  tenantWebsiteLabel?: string;
}

const Logo = styled.img`
  height: auto;
  width: 60%;
`;

const LogoContainer = styled.div`
  height: 20%;
  margin-top: 10%;
  margin-bottom: 30%;
`;

const CheckmarkIcon = styled.img`
  height: 100px;
  width: 100px;
  margin-bottom: 3%;
`;

const Headline = styled.h1`
  margin-top: 0px;
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 31px;
  line-height: 37px;
  text-align: center;
  color: #001b2b;
`;

const OrderPaid = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 120%;
  text-align: center;
  color: #001b2b;
  margin-bottom: 250px;
`;

const Website = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  text-decoration-line: underline;
  color: #001b2b;
  margin-bottom: 20px;
`;

const Footer = styled.div`
  margin-bottom: 20px;
  position: fixed;
  bottom: 0;
  text-align: center;
  left: 0;
  width: 100%;
`;

const Success = (props: SuccessProps) => {
  const { customer, tenantWebsite, tenantWebsiteLabel } = props;

  ReactGA.pageview('Success');

  return (
    <div>
      <LogoContainer>
        <Logo src="customization/logo.png" />
      </LogoContainer>
      <CheckmarkIcon src={checkmarkIcon} alt="Success Icon" />
      <Headline>
        {typeof customer?.firstName === 'string'
          ? `Thank you ${customer.firstName}!`
          : 'Thank you!'}
      </Headline>
      <OrderPaid>Your order has been paid.</OrderPaid>
      <Footer>
        {tenantWebsite !== undefined && (
          <Website data-testid="tenant-website" href={tenantWebsite}>
            {tenantWebsiteLabel !== undefined
              ? tenantWebsiteLabel
              : tenantWebsite}
          </Website>
        )}
      </Footer>
    </div>
  );
};

export default Success;
