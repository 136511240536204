import * as React from 'react';
import * as ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';
import styled from 'styled-components';
import { fetchCartConfiguration } from './api';
import Success from './screens/Success';
import LoadingPage from './screens/Loading';

// @ts-expect-error as this is legacy and not in use anymore
import ApplePayScreen from './screens/ApplePayScreen';

import WebDropInScreen from './screens/WebDropInScreen';
import ErrorPage from './screens/ErrorPage';
import type {
  CustomerName,
  WebDropInConfig,
  setLoadingType,
  CartConfig,
  markAsPaidType
} from './types';
import CheckoutClientError from './errors';
import cond from 'cond-flow';

const Container = styled.div`
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  background: #f6f6f6;
`;

const CenteredContainer = styled.div`
  text-align: center;
  height: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

const AppContainer: React.FC<{ children: JSX.Element }> = ({ children }) => (
  <Container>
    <CenteredContainer>{children}</CenteredContainer>
  </Container>
);

interface AppState {
  amount?: number;
  currency?: string;
  cartId?: string;
  loading: boolean;
  paid: boolean;
  webDropInConfig?: WebDropInConfig;
  error?: Error;
  customer?: CustomerName;
  tenantUrl?: string;
  tenantUrlLabel?: string;
}

interface LegacyApplePayProps {
  amount: number;
  currency: string;
  markAsPaid: markAsPaidType;
}

const LegacyApplePay = ({
  amount,
  currency,
  markAsPaid
}: LegacyApplePayProps): JSX.Element => {
  const error = cond([
    [
      (global as any).ApplePaySession === undefined,
      {
        sentryError: 'Not a safari browser.',
        errorMessage: 'Browser is not supported. Please try with Safari.'
      }
    ],
    [
      (global as any).ApplePaySession !== undefined &&
        !ApplePaySession.canMakePayments(),
      {
        sentryError: 'Apple Pay is not enabled.',
        errorMessage:
          'Not possible to perform Apple Pay payments on this device.'
      }
    ]
  ]);

  if (error !== null) {
    Sentry.captureMessage(error.sentryError, Sentry.Severity.Info);

    return (
      <AppContainer>
        <ErrorPage message={error.errorMessage} />
      </AppContainer>
    );
  }

  return (
    <ApplePayScreen
      amount={amount}
      currency={currency}
      markAsPaid={markAsPaid}
    />
  );
};

export default class App extends React.Component<{}, AppState> {
  constructor(props: {}) {
    super(props);

    this.state = {
      amount: undefined,
      currency: undefined,
      cartId: undefined,
      loading: true,
      paid: false,
      error: undefined,
      customer: undefined,
      tenantUrl: undefined,
      tenantUrlLabel: undefined,
      webDropInConfig: {
        enabled: false,
        paymentMethods: undefined,
        originKey: undefined,
        countryCode: undefined,
        locale: undefined,
        environment: undefined,
        merchantAccount: undefined,
        applePay: undefined,
        googlePay: undefined,
        paypal: undefined,
        scheme: undefined
      }
    };
  }

  componentDidMount = async () => {
    ReactGA.initialize('UA-119902542-1');
    ReactGA.pageview('Overview');

    void fetchCartConfiguration()
      .then((config: CartConfig) => {
        this.setState({
          paid: config.amount === 0,
          amount: config.amount,
          cartId: config.cartId,
          currency: config.currency,
          tenantUrl: config.tenantUrl,
          tenantUrlLabel: config.tenantUrlLabel,
          webDropInConfig: config.webDropInConfig
        });
      })
      .catch((error: Error) => {
        this.setState({ error });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  markAsFailed = (error: Error) => {
    if (error instanceof CheckoutClientError) {
      this.setState({ error });
    } else {
      this.setState({ error: Error('The payment could not be processed') });
    }
  };

  markAsPaid = (customer?: CustomerName) =>
    this.setState({ paid: true, customer });

  setLoading: setLoadingType = (loading) => {
    this.setState({ loading });
  };

  render() {
    const {
      paid,
      error,
      loading,
      amount,
      currency,
      customer,
      tenantUrl,
      tenantUrlLabel,
      webDropInConfig
    } = this.state;

    if (error !== undefined) {
      return (
        <AppContainer>
          <ErrorPage message={error.message} />
        </AppContainer>
      );
    }

    if (loading) return <LoadingPage />;

    if (
      typeof amount !== 'number' ||
      typeof currency !== 'string' ||
      webDropInConfig === undefined
    ) {
      return null;
    }

    const content = cond([
      [
        paid,
        () => (
          <Success
            customer={customer}
            tenantWebsite={tenantUrl}
            tenantWebsiteLabel={tenantUrlLabel}
          />
        )
      ],
      [
        !webDropInConfig.enabled,
        () => (
          <LegacyApplePay
            amount={amount}
            currency={currency}
            markAsPaid={this.markAsPaid}
          />
        )
      ],
      [
        true,
        () => (
          <WebDropInScreen
            amount={amount}
            currency={currency}
            webDropInConfig={webDropInConfig}
            markAsPaid={this.markAsPaid}
            markAsFailed={this.markAsFailed}
            setLoading={this.setLoading}
          />
        )
      ]
    ]) as JSX.Element;

    return <AppContainer>{content}</AppContainer>;
  }
}
