import * as Sentry from '@sentry/browser';
import axios, { AxiosError } from 'axios';
import parseUrl from 'url-parse';
import type {
  CurrentScope,
  CartConfig,
  GetShoppingCartResponse,
  WebDropInConfig
} from './types';
import CheckoutClientError from './errors';

type GetCurrentScope = (currentHref: string) => CurrentScope;

/**
 * Instead of sending the base url, it's also possible
 * to give tenant and stage, the base url will be constructed
 * then:
 * tenant = dodici-demo, stage = s -> https://newstorecheckout.dodici-demo.s.newstore.net
 * tenant = custom, stage = x -> https://newstorecheckout.custom.x.newstore.net
 */
export const getCurrentScope: GetCurrentScope = (currentHref) => {
  const url = parseUrl(currentHref, true);
  const urlParts = url.hostname.split('.');

  const tenant = urlParts[1];
  const stage = urlParts[2];

  const baseUrl = `https://${tenant}.${stage}.newstore.net`;

  return {
    cartId: url.pathname.replace('/', ''),
    baseUrl,
    tenant,
    stage
  };
};

export const arePaymentMethodsDefined = (paymentMethods?: {}): boolean => {
  if (paymentMethods !== undefined) {
    return Object.keys(paymentMethods).length > 0;
  }

  return false;
};

export const fetchCartConfiguration = async (): Promise<CartConfig> => {
  const { cartId, baseUrl } = getCurrentScope(window.location.href);

  if (cartId === '') {
    Sentry.captureMessage('URL does not contain cart id', Sentry.Severity.Info);
    throw new Error('Incomplete URL!');
  }

  try {
    const { data } = await axios.get<GetShoppingCartResponse>(
      `${baseUrl}/_/v0/newstorecheckout/carts/${cartId}`,
      {}
    );

    const queriedWebDropInConfig = data.configuration.web_drop_in;
    const webDropInEnabled = queriedWebDropInConfig?.enabled ?? false;

    const amount = data.cart.balance_due?.amount ?? 0;
    const currency = data.cart.balance_due?.currency ?? '';
    const tenantUrl = data.configuration.tenant_url;
    const tenantUrlLabel = data.configuration.tenant_url_label;

    if (webDropInEnabled) {
      if (
        !arePaymentMethodsDefined(
          queriedWebDropInConfig?.payment_methods as {} | undefined
        )
      ) {
        throw new CheckoutClientError(
          'Payment method not set up. Contact your associate.'
        );
      }

      const webDropInConfig: WebDropInConfig = {
        enabled: webDropInEnabled,
        paymentMethods: queriedWebDropInConfig?.payment_methods,
        originKey: queriedWebDropInConfig?.origin_key,
        countryCode: queriedWebDropInConfig?.country_code,
        locale: queriedWebDropInConfig?.locale,
        environment: queriedWebDropInConfig?.environment,
        merchantAccount: queriedWebDropInConfig?.merchant_account,
        applePay:
          queriedWebDropInConfig?.apple_pay !== undefined
            ? {
                merchantID: queriedWebDropInConfig?.apple_pay?.merchant_id,
                supportedNetworks:
                  queriedWebDropInConfig?.apple_pay?.supported_networks
              }
            : undefined,
        googlePay:
          queriedWebDropInConfig?.google_pay !== undefined
            ? {
                merchantID: queriedWebDropInConfig?.google_pay?.merchant_id
              }
            : undefined,
        paypal:
          queriedWebDropInConfig?.paypal !== undefined
            ? { merchantID: queriedWebDropInConfig?.paypal?.merchant_id }
            : undefined,
        scheme:
          queriedWebDropInConfig?.scheme !== undefined
            ? {
                captureBillingAddress:
                  queriedWebDropInConfig.scheme.capture_billing_address ?? false
              }
            : undefined
      };

      return {
        amount,
        currency,
        tenantUrl,
        tenantUrlLabel,
        cartId,
        webDropInConfig
      };
    }

    return {
      amount,
      currency,
      tenantUrl,
      tenantUrlLabel,
      cartId
    };
  } catch (error) {
    const requestError = error as AxiosError;

    if ((requestError.response?.status ?? 0) !== 404) {
      Sentry.captureException(requestError);
    }

    throw requestError;
  }
};
