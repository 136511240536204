import React, { Component } from 'react';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import styled from 'styled-components';
import { getCurrentScope } from '../api';
import { stripApplePayAddress as stripAddress } from '../utils';
import Preparing from './Preparing';
import Failure from './Failure';

const Button = styled.button`
  -webkit-appearance: -apple-pay-button !important;
  -apple-pay-button-type: plain;
  -apple-pay-button-style: black;
  border: 0;
  margin-left: 0;
  margin-right: 0;
  width: 80%;
  height: 50px;
`;

export default class ApplePayButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentLoading: true,
      canMakePayment: false,
      paymentSession: null,
      error: null
    };

    const { cartId, baseUrl } = getCurrentScope(window.location.href);

    axios
      .post(
        `${baseUrl}/_/v0/newstorecheckout/carts/${cartId}/payment_sessions`,
        {
          payment_wallet: 'APPLE_PAY'
        }
      )
      .then(({ data, status }) => {
        const paymentSession = data.payment_wallet_session;
        delete paymentSession.request_id;

        this.setState({
          canMakePayment: status === 200,
          paymentLoading: false,
          paymentSession
        });
      })
      .catch((error) => {
        // received something different from 200 OK
        console.log('could not get a session');
        console.log(error);
        Sentry.withScope((scope) => {
          scope.setExtra('error', error);
          Sentry.captureMessage('Error getting session', 'error');
        });

        this.setState({
          paymentLoading: false,
          error: new Error('Unexpected error. Try again later.')
        });
      });
  }

  handleOnPress = () => {
    Sentry.addBreadcrumb({
      message: 'Apple Pay',
      category: 'ui'
    });

    const { amount, currency, markAsPaid } = this.props;

    const request = {
      countryCode: 'US',
      currencyCode: currency,
      supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
      merchantCapabilities: ['supports3DS'],
      total: { label: '', amount },
      requiredBillingContactFields: ['postalAddress'],
      requiredShippingContactFields: ['email', 'name', 'phone']
    };
    const session = new global.ApplePaySession(3, request);
    const { cartId, baseUrl } = getCurrentScope(window.location.href);

    session.onpaymentauthorized = async ({ payment }) => {
      const { shippingContact, billingContact } = payment;
      try {
        await axios
          .post(`${baseUrl}/_/v0/newstorecheckout/carts/${cartId}/_pay`, {
            payment_token: btoa(JSON.stringify(payment.token.paymentData)),
            payment_provider: 'ADYEN',
            payment_wallet: 'APPLE_PAY',
            amount,
            currency,
            customer: {
              name: `${shippingContact.givenName} ${shippingContact.familyName}`,
              email: shippingContact.emailAddress,
              phone: shippingContact.phoneNumber
            },
            billing_address: stripAddress(billingContact)
          })
          .then(({ status }) => {
            if (status === 200) {
              console.log('payment authorized');
              session.completePayment(global.ApplePaySession.STATUS_SUCCESS);
              markAsPaid({
                firstName: shippingContact.givenName,
                lastName: shippingContact.familyName
              });
            } else {
              console.log(`payment was not authorized ${status}`);
              this.setState({
                paymentLoading: false,
                error: new Error('Payment was not authorized.')
              });
              Sentry.withScope((scope) => {
                scope.setExtra('resultCode', status);
                Sentry.captureMessage('Payment authorization failed', 'error');
              });
              session.completePayment(global.ApplePaySession.STATUS_FAILURE);
            }
          })
          .catch((error) => {
            // received something different from 200 OK
            console.log('could not authorize payment session');
            console.log(error);
            Sentry.withScope((scope) => {
              scope.setExtra('error', error);
              Sentry.captureMessage('Error authorizing payment', 'error');
            });

            session.completePayment(global.ApplePaySession.STATUS_FAILURE);
            this.setState({
              paymentLoading: false,
              error: new Error('Unexpected error. Try again later.')
            });
          });
      } catch (error) {
        console.log('Unexpected error');
        console.log(error);
        this.setState({
          error: new Error('Unexpected error. Try again later.')
        });
        Sentry.withScope((scope) => {
          scope.setExtra('error', error);
          Sentry.captureMessage('Unexpected error', 'error');
        });
        session.completePayment(global.ApplePaySession.STATUS_FAILURE);
      }
    };

    session.onvalidatemerchant = () => {
      const { paymentSession } = this.state;
      session.completeMerchantValidation(paymentSession);
    };

    session.oncancel = (event) => {
      console.log('Session canceled');
      console.log(event);
      Sentry.withScope(() => {
        Sentry.captureMessage('Session canceled', 'info');
      });
      this.setState({
        paymentLoading: false
      });
    };

    session.onerror = ({ error }) => {
      console.log('Unexpected error.');
      console.log(error);
      Sentry.withScope((scope) => {
        scope.setExtra('error', error);
        Sentry.captureMessage('Unexpected error.', 'error');
      });
      this.setState({
        paymentLoading: false,
        error: new Error('Unexpected error. Try again later.')
      });
    };

    session.onpaymentmethodselected = () => {
      const newTotal = { type: 'final', label: '', amount };
      session.completePaymentMethodSelection(
        newTotal,
        [] // newLineItems
      );
    };

    session.begin();
  };

  render() {
    const { paymentLoading, canMakePayment, error } = this.state;
    if (paymentLoading) return <Preparing />;
    if (error) return <Failure message={error.message} />;
    if (!canMakePayment)
      return (
        <Failure
          message="We could not detect Apple Pay on your device. Make sure it is available
        in your region and correctly set up on your device."
        />
      );

    return (
      <Button
        type="button"
        onClick={this.handleOnPress}
        data-testid="apple-pay-button"
      />
    );
  }
}
