import { AdyenAddress, BillingAddress } from 'types';

export const stripApplePayAddress = (
  address: AdyenAddress
): BillingAddress => ({
  address_line_1: address.addressLines?.[0] ?? '',
  address_line_2: address.addressLines?.[1] ?? '',
  name: `${address.givenName ?? ''} ${address.familyName ?? ''}`.trim(),
  city: address.locality,
  zip_code: address.postalCode,
  state: address.administrativeArea,
  country: (address.countryCode ?? '').toUpperCase()
});

export const customerFirstAndLastName = (
  customerName: string = ''
): { firstName: string; lastName: string } => {
  const customerSplitName = customerName.split(' ');
  const firstName = customerSplitName
    .slice(0, customerSplitName.length - 1)
    .join(' ');
  const lastName = customerSplitName[customerSplitName.length - 1];

  return {
    firstName,
    lastName
  };
};
