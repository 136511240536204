import React from 'react';
import styled from 'styled-components';
import ApplePayButton from '../components/ApplePayButton';
import { getCurrencyFractionDigits } from '../monetary';
import background from '../assets/img/background.jpg';

const Background = styled.div`
  background: linear-gradient(
      rgba(248, 248, 248, 0.82),
      rgba(248, 248, 248, 0.82)
    ),
    url(${background});
  background-size: cover;
  filter: blur(10px);
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
`;

const Container = styled.div`
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;

  @media only screen and (min-device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    padding-top: 15%;
  }
  @media only screen and (min-device-width: 414px) {
    padding-top: 10%;
  }
`;

const Icon = styled.img`
  font-size: 7em;
  color: #d591ab;
  height: 35%;
  margin-top: 10%;
`;

const Text = styled.p`
  color: #acaaa8;
  font-size: 0.9em;
  display: block;
  width: 80%;
  margin: auto;
  margin-top: 5%;
  margin-bottom: 5%;
  line-height: 1.5em;
`;

const PrivacyLink = styled.a`
  text-decoration: underline dashed #acaaa8;
`;

const Amount = styled.h1`
  font-size: 50px;
  font-weight: 400;
  margin-top: 8%;
  margin-bottom: 0;
`;

const ApplePayScreen = ({
  amount,
  currency,
  associate, // TODO: read it from the cart response once it's available
  markAsPaid,
  privacyUrl // TODO: make it configurable via config endpoint or remove it
}) => (
  <div>
    <Background />
    <Container>
      <Icon src="customization/logo.png" />
      <Amount>
        {amount.toLocaleString(['en'], {
          minimumFractionDigits: getCurrencyFractionDigits(currency),
          style: 'currency',
          currency: String(currency || 'USD')
        })}
      </Amount>
      {associate && (
        <Text>
          <b>{`sent from ${associate}`}</b>
        </Text>
      )}
      <ApplePayButton
        amount={amount}
        currency={String(currency)}
        markAsPaid={markAsPaid}
      />
      {privacyUrl && (
        <Text>
          By using this website, you agree to our
          <br />
          <PrivacyLink href="{privacyUrl}">
            privacy policy and terms and conditions
          </PrivacyLink>
        </Text>
      )}
    </Container>
  </div>
);

export default ApplePayScreen;
