/* eslint-disable no-unused-vars */
import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/browser';
import * as R from 'ramda';
import { getCurrentScope } from '../api';
import type {
  markAsPaidType,
  markAsFailedType,
  setLoadingType,
  WebDropInConfig,
  setMetadataType,
  getMetadataType,
  DropInFormState
} from '../types';

import { customerFirstAndLastName } from '../utils';
import CheckoutClientError from '../errors';
import { CardElementProps } from '@adyen/adyen-web/dist/types/components/Card/types';

const cardWallet = 'DIRECT';
const adyenProvider = 'ADYEN';

interface HandleOnSubmitArgs {
  state: DropInFormState;
  cartId: string;
  baseUrl: string;
  amount: number;
  currency: string;
  markAsPaid: markAsPaidType;
  markAsFailed: markAsFailedType;
  setLoading: setLoadingType;
  getMetadata: getMetadataType;
}

interface GetCardArgs {
  webDropInConfig: WebDropInConfig;
  amount: number;
  currency: string;
  markAsPaid: markAsPaidType;
  markAsFailed: markAsFailedType;
  setLoading: setLoadingType;
  setMetadata: setMetadataType;
  getMetadata: getMetadataType;
}

const adaptPayError = (
  error: AxiosError<{ error_code: string }>
): CheckoutClientError => {
  switch (error?.response?.data?.error_code) {
    case 'adyen_refusal_error':
      return new CheckoutClientError(
        `Payment rejected. Try a different payment method.`
      );
    case 'unsupported_payment_details_error':
      return new CheckoutClientError(`Payment rejected. Try a different card.`);
    default:
      return new CheckoutClientError(`There was an error. Try again.`);
  }
};

export const handleOnSubmit = async ({
  state,
  cartId,
  baseUrl,
  amount,
  currency,
  markAsPaid,
  markAsFailed,
  setLoading,
  getMetadata
}: HandleOnSubmitArgs): Promise<void> => {
  return await new Promise((resolve, reject) => {
    const token = JSON.stringify(state?.data?.paymentMethod);

    const customerName = state?.data?.paymentMethod?.holderName ?? '';
    const { firstName, lastName } = customerFirstAndLastName(customerName);

    const customer = getCustomerInfo(state);

    setLoading(true);
    axios
      .post(`${baseUrl}/_/v0/newstorecheckout/carts/${cartId}/_pay`, {
        payment_token: token,
        payment_provider: adyenProvider,
        payment_wallet: cardWallet,
        amount,
        currency,
        customer,
        metadata: getMetadata()
      })
      .then(() => {
        markAsPaid({
          firstName,
          lastName
        });
        setLoading(false);
        resolve();
      })
      .catch((error) => {
        Sentry.captureException(error);
        const payError = adaptPayError(error);
        markAsFailed(payError);
        setLoading(false);
        reject(payError);
      });
  });
};

const getCustomerInfo = (state: HandleOnSubmitArgs['state']) => {
  const customerName = state?.data?.paymentMethod?.holderName ?? '';
  const billingAddress = state.data.billingAddress ?? {};

  return {
    name: customerName,
    ...(R.isEmpty(billingAddress)
      ? {}
      : {
          billing_address: {
            street: billingAddress.street,
            house_number: billingAddress.houseNumberOrName,
            postal_code: billingAddress.postalCode,
            city: billingAddress.city,
            country: billingAddress.country,
            ...(billingAddress.stateOrProvince !== undefined
              ? { state: billingAddress.stateOrProvince }
              : {})
          }
        })
  };
};

export const getCard = ({
  webDropInConfig,
  amount,
  currency,
  markAsPaid,
  markAsFailed,
  setLoading,
  setMetadata,
  getMetadata
}: GetCardArgs): CardElementProps => {
  const { cartId, baseUrl } = getCurrentScope(window.location.href);
  return {
    hasHolderName: true,
    holderNameRequired: true,
    enableStoreDetails: false,
    billingAddressRequired:
      webDropInConfig?.scheme?.captureBillingAddress ?? false,
    hideCVC: false, // Change this to true to hide the CVC field for stored cards
    name: 'Credit or debit card',

    onFieldValid: (data) => {
      if (data.fieldType === 'encryptedCardNumber' && data.valid) {
        setMetadata({ last_4: data.endDigits });
      }
    },
    onBrand: (data) => {
      setMetadata({ brand: data.brand });
    },
    onSubmit: async (state) =>
      await handleOnSubmit({
        state,
        cartId,
        baseUrl,
        amount,
        currency,
        markAsPaid,
        markAsFailed,
        setLoading,
        getMetadata
      })
  };
};
