import * as React from 'react';
import styled from 'styled-components';
import AdyenDropIn from '../components/AdyenDropIn';
import type {
  markAsPaidType,
  markAsFailedType,
  setLoadingType,
  WebDropInConfig
} from '../types';
import { getCurrencyFractionDigits } from '../monetary';

interface WebDropInScreenProps {
  amount: number;
  currency?: string;
  webDropInConfig: WebDropInConfig;
  markAsPaid: markAsPaidType;
  markAsFailed: markAsFailedType;
  setLoading: setLoadingType;
}

const Logo = styled.img`
  height: auto;
  width: 60%;
`;

const LogoContainer = styled.div`
  height: 20%;
  margin-top: 10%;
`;

const Amount = styled.h1`
  font-size: 60px;
  font-style: normal;
  font-weight: normal;
  line-height: 72px;
  margin-bottom: 0;
  margin-top: 20%;
  color: #001b2b;
`;

const AmountLabel = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #687281;
  margin-bottom: 40px;
  margin-top: 0px;
`;

const WebDropInScreen = ({
  amount,
  currency,
  webDropInConfig,
  markAsPaid,
  markAsFailed,
  setLoading
}: WebDropInScreenProps) => (
  <div>
    <LogoContainer>
      <Logo src="customization/logo.png" />
    </LogoContainer>
    <Amount>
      {amount.toLocaleString(['en'], {
        minimumFractionDigits: getCurrencyFractionDigits(currency),
        style: 'currency',
        currency: String(currency ?? 'USD')
      })}
    </Amount>
    <AmountLabel>Amount to pay</AmountLabel>
    <AdyenDropIn
      webDropInConfig={webDropInConfig}
      amount={amount}
      currency={String(currency)}
      markAsPaid={markAsPaid}
      markAsFailed={markAsFailed}
      setLoading={setLoading}
    />
  </div>
);

export default WebDropInScreen;
